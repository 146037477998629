.results_component_root {
  width: 1920px;
  height: 1080px;
  background-image: url("../public/results.png");
  background-size: 1920px 1080px;
  background-repeat: no-repeat;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}
.drawing_root_component {
  background-image: url("../public/background.png");
  width: 1920px;
  height: 1080px;
  background-size: 1920px 1080px;
  background-repeat: no-repeat;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}

.results_jackpot_value,
.draw_jackpot_value {
  position: absolute;
  font-size: 2.7vw;
  font-weight: 900;
  color: white;
  width: 210px;
  top: 90px;
  text-align: center;
}
.jackGreen {
  color: green;
}
.results_bonus_img {
  position: absolute;
  top: 98px;
  left: 194px;
}
.results_bonus_img2 {
  position: absolute;
  top: 98px;
  left: 290px;
}
.results_bonus_img3 {
  position: absolute;
  top: 97px;
  left: 387px;
}
.results_round_number {
  position: absolute;
  font-size: 2.1vw;
  font-weight: 700;
  color: white;
  left: 930px;
  top: 15px;
  width: 240px;
  text-align: center;
}
.results_jackpot_code {
  position: absolute;
  font-size: 2.4vw;
  font-weight: 900;
  color: white;
  top: 90px;
  left: 1640px;
  width: 250px;
  text-align: center;
}
.results_bet_time_remains {
  position: absolute;
  font-size: 2.4vw;
  font-weight: 600;
  color: green;
  top: 1010px;
  left: 1740px;
  text-align: center;
  width: 150px;
  height: 54px;
  line-height: 1.2;
  background-color: white;
}

.results_ball_1,
.drawn_ball_1 {
  position: absolute;
  left: 539px;
  top: 77px;
}
.results_ball_2,
.drawn_ball_2 {
  position: absolute;
  top: 77px;
  left: 643px;
}
.results_ball_3,
.drawn_ball_3 {
  position: absolute;
  top: 77px;
  left: 747px;
}
.results_ball_4,
.drawn_ball_4 {
  position: absolute;
  top: 77px;
  left: 850px;
}
.results_ball_5,
.drawn_ball_5 {
  position: absolute;
  top: 77px;
  left: 954px;
}
.results_ball_1 img,
.results_ball_2 img,
.results_ball_3 img,
.results_ball_4 img,
.results_ball_5 img,
.results_trifoi_1 img,
.results_trifoi_2 img,
.drawn_ball_1 img,
.drawn_ball_2 img,
.drawn_ball_3 img,
.drawn_ball_4 img,
.drawn_ball_5 img {
  width: 72px;
  height: 72px;
}
.results_ball_1 div,
.results_ball_2 div,
.results_ball_3 div,
.results_ball_4 div,
.results_ball_5 div,
.results_trifoi_1 div,
.results_trifoi_2 div,
.drawn_ball_1 div,
.drawn_ball_2 div,
.drawn_ball_3 div,
.drawn_ball_4 div,
.drawn_ball_5 div {
  position: absolute;
  font-size: 27px;
  font-weight: 600;
  width: 72px;
  height: 72px;
  text-align: center;
  top: 20px;
}
.span_sum_first_five {
  position: absolute;
  top: 105px;
  left: 1035px;
  font-size: 30px;
  color: white;
  font-weight: 600;
}
.results_header_trifoi,
.results_header_trifoi_draw {
  position: absolute;
  top: 77px;
  left: 1104px;
}
.results_header_trifoi_draw {
  top: 81px;
  left: 1107px;
}
.results_header_trifoi img {
  width: 72px;
  height: 72px;
}
.results_header_trifoi_draw img {
  width: 66px;
  height: 66px;
}
.results_trifoi_1 {
  position: absolute;
  top: 77px;
  left: 1206px;
}
.results_trifoi_2 {
  position: absolute;
  top: 77px;
  left: 1310px;
}

.results_color_value {
  position: absolute;
  left: 936px;
  top: 228px;
}
.results_color_value div {
  width: 45px;
  height: 45px;
}
.redBack {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: red;
}
.blackBack {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: black;
}
.blueBack {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: blue;
}
.brownBack {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: brown;
}
.greenBack {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: green;
}
.orangeBack {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: orange;
}
.purpleBack {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: purple;
}
.yellowBack {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: gold;
}
.results_color_label {
  position: absolute;
  font-size: 30px;
  top: 222px;
  left: 1000px;
  width: 300px;
  height: 50px;
  text-align: center;
  line-height: 1.8;
  font-weight: 600;
}

.results_firstnr_value {
  position: absolute;
  top: 310px;
  left: 936px;
}
.results_firstnr_value img {
  width: 45px;
  height: 45px;
}
.results_firstnr_value div {
  position: absolute;
  font-size: 18px;
  font-weight: 600;
  width: 72px;
  height: 72px;
  text-align: center;
  top: 12px;
  left: -13px;
}
.results_firstnr_label {
  position: absolute;
  font-size: 30px;
  top: 306px;
  left: 1000px;
  width: 300px;
  height: 50px;
  text-align: center;
  line-height: 1.8;
  font-weight: 600;
}
.results_evenodd_value {
  position: absolute;
  top: 380px;
  left: 930px;
  font-size: 36px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 1.8;
  color: red;
  font-weight: 600;
}
.results_evenodd_label {
  position: absolute;
  font-size: 30px;
  top: 386px;
  left: 1000px;
  width: 300px;
  height: 50px;
  text-align: center;
  line-height: 1.8;
  font-weight: 600;
}
.results_sum_value {
  position: absolute;
  top: 462px;
  left: 930px;
  font-size: 36px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 1.8;
  color: red;
  font-weight: 600;
}
.results_sum_label {
  position: absolute;
  font-size: 30px;
  top: 466px;
  left: 1000px;
  width: 300px;
  height: 50px;
  text-align: center;
  line-height: 1.8;
  font-weight: 600;
}

.results_ball_6,
.drawn_ball_6 {
  position: absolute;
  top: 184px;
  left: 83px;
}
.results_ball_7,
.drawn_ball_7 {
  position: absolute;
  top: 320px;
  left: 83px;
}
.results_ball_8,
.drawn_ball_8 {
  position: absolute;
  top: 454px;
  left: 82px;
}
.results_ball_9,
.drawn_ball_9 {
  position: absolute;
  top: 588px;
  left: 82px;
}
.results_ball_10,
.drawn_ball_10 {
  position: absolute;
  left: 82px;
  top: 722px;
}
.results_ball_11,
.drawn_ball_11 {
  position: absolute;
  left: 82px;
  top: 857px;
}
.results_ball_12,
.drawn_ball_12 {
  position: absolute;
  top: 184px;
  left: 396px;
}
.results_ball_13,
.drawn_ball_13 {
  position: absolute;
  left: 396px;
  top: 320px;
}
.results_ball_14,
.drawn_ball_14 {
  position: absolute;
  left: 396px;
  top: 454px;
}
.results_ball_15,
.drawn_ball_15 {
  position: absolute;
  left: 396px;
  top: 588px;
}
.results_ball_16,
.drawn_ball_16 {
  position: absolute;
  left: 396px;
  top: 722px;
}
.results_ball_17,
.drawn_ball_17 {
  position: absolute;
  left: 396px;
  top: 857px;
}
.results_ball_18,
.drawn_ball_18 {
  position: absolute;
  top: 588px;
  left: 709px;
}
.results_ball_19,
.drawn_ball_19 {
  position: absolute;
  left: 709px;
  top: 722px;
}
.results_ball_20,
.drawn_ball_20 {
  position: absolute;
  left: 709px;
  top: 857px;
}
.results_ball_21,
.drawn_ball_21 {
  position: absolute;
  left: 1023px;
  top: 588px;
}
.results_ball_22,
.drawn_ball_22 {
  position: absolute;
  left: 1023px;
  top: 722px;
}
.results_ball_23,
.drawn_ball_23 {
  position: absolute;
  left: 1023px;
  top: 857px;
}
.results_ball_24,
.drawn_ball_24 {
  position: absolute;
  left: 1335px;
  top: 184px;
}
.results_ball_25,
.drawn_ball_25 {
  position: absolute;
  left: 1335px;
  top: 320px;
}
.results_ball_26,
.drawn_ball_26 {
  position: absolute;
  left: 1335px;
  top: 454px;
}
.results_ball_27,
.drawn_ball_27 {
  position: absolute;
  left: 1335px;
  top: 588px;
}
.results_ball_28,
.drawn_ball_28 {
  position: absolute;
  left: 1335px;
  top: 722px;
}
.results_ball_29,
.drawn_ball_29 {
  position: absolute;
  left: 1335px;
  top: 857px;
}
.results_ball_30,
.drawn_ball_30 {
  position: absolute;
  top: 184px;
  left: 1649px;
}
.results_ball_31,
.drawn_ball_31 {
  position: absolute;
  top: 320px;
  left: 1649px;
}
.results_ball_32,
.drawn_ball_32 {
  position: absolute;
  top: 454px;
  left: 1649px;
}
.results_ball_33,
.drawn_ball_33 {
  position: absolute;
  top: 588px;
  left: 1649px;
}
.results_ball_34,
.drawn_ball_34 {
  position: absolute;
  top: 722px;
  left: 1649px;
}
.results_ball_35,
.drawn_ball_35 {
  position: absolute;
  top: 857px;
  left: 1649px;
}
.results_ball_6 img,
.results_ball_7 img,
.results_ball_8 img,
.results_ball_9 img,
.results_ball_10 img,
.results_ball_11 img,
.results_ball_12 img,
.results_ball_13 img,
.results_ball_14 img,
.results_ball_15 img,
.results_ball_16 img,
.results_ball_17 img,
.results_ball_18 img,
.results_ball_19 img,
.results_ball_20 img,
.results_ball_21 img,
.results_ball_22 img,
.results_ball_23 img,
.results_ball_24 img,
.results_ball_25 img,
.results_ball_26 img,
.results_ball_27 img,
.results_ball_28 img,
.results_ball_29 img,
.results_ball_30 img,
.results_ball_31 img,
.results_ball_32 img,
.results_ball_33 img,
.results_ball_34 img,
.results_ball_35 img,
.drawn_ball_6 img,
.drawn_ball_7 img,
.drawn_ball_8 img,
.drawn_ball_9 img,
.drawn_ball_10 img,
.drawn_ball_11 img,
.drawn_ball_12 img,
.drawn_ball_13 img,
.drawn_ball_14 img,
.drawn_ball_15 img,
.drawn_ball_16 img,
.drawn_ball_17 img,
.drawn_ball_18 img,
.drawn_ball_19 img,
.drawn_ball_20 img,
.drawn_ball_21 img,
.drawn_ball_22 img,
.drawn_ball_23 img,
.drawn_ball_24 img,
.drawn_ball_25 img,
.drawn_ball_26 img,
.drawn_ball_27 img,
.drawn_ball_28 img,
.drawn_ball_29 img,
.drawn_ball_30 img,
.drawn_ball_31 img,
.drawn_ball_32 img,
.drawn_ball_33 img,
.drawn_ball_34 img,
.drawn_ball_35 img {
  width: 108px;
  height: 108px;
}
.results_ball_6 div,
.results_ball_7 div,
.results_ball_8 div,
.results_ball_9 div,
.results_ball_10 div,
.results_ball_11 div,
.results_ball_12 div,
.results_ball_13 div,
.results_ball_14 div,
.results_ball_15 div,
.results_ball_16 div,
.results_ball_17 div,
.results_ball_18 div,
.results_ball_19 div,
.results_ball_20 div,
.results_ball_21 div,
.results_ball_22 div,
.results_ball_23 div,
.results_ball_24 div,
.results_ball_25 div,
.results_ball_26 div,
.results_ball_27 div,
.results_ball_28 div,
.results_ball_29 div,
.results_ball_30 div,
.results_ball_31 div,
.results_ball_32 div,
.results_ball_33 div,
.results_ball_34 div,
.results_ball_35 div,
.drawn_ball_6 div,
.drawn_ball_7 div,
.drawn_ball_8 div,
.drawn_ball_9 div,
.drawn_ball_10 div,
.drawn_ball_11 div,
.drawn_ball_12 div,
.drawn_ball_13 div,
.drawn_ball_14 div,
.drawn_ball_15 div,
.drawn_ball_16 div,
.drawn_ball_17 div,
.drawn_ball_18 div,
.drawn_ball_19 div,
.drawn_ball_20 div,
.drawn_ball_21 div,
.drawn_ball_22 div,
.drawn_ball_23 div,
.drawn_ball_24 div,
.drawn_ball_25 div,
.drawn_ball_26 div,
.drawn_ball_27 div,
.drawn_ball_28 div,
.drawn_ball_29 div,
.drawn_ball_30 div,
.drawn_ball_31 div,
.drawn_ball_32 div,
.drawn_ball_33 div,
.drawn_ball_34 div,
.drawn_ball_35 div {
  position: absolute;
  font-size: 42px;
  font-weight: 1000;
  font-weight: bolder;
  width: 72px;
  height: 72px;
  text-align: center;
  top: 30px;
  left: 18px;
}

.results_bottom_label {
  position: absolute;
  top: 1030px;
  left: 360px;
  width: 1270px;
  height: 40px;
  text-align: center;
  color: white;
  font-weight: 900;
  font-size: 27px;
}

.current_extracted_ball {
  position: absolute;
  top: 219px;
  left: 808px;
  animation: anim 3s;
}
.current_extracted_ball img {
  width: 300px;
  height: 300px;
}
.current_extracted_ball div {
  position: absolute;
  font-size: 120px;
  font-weight: 1000;
  font-weight: 900;
  width: 210px;
  height: 60px;
  text-align: center;
  top: 78px;
  left: 45px;
}
@keyframes anim {
  0% {
    transform: scale(0);
  }
  81% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.timer_before_round {
  position: absolute;
  top: 320px;
  left: 808px;
  width: 300px;
  height: 300px;
  color: whitesmoke;
  font-size: 90px;
  font-weight: bolder;
  text-align: center;
}
.final_message {
  position: absolute;
  top: 312px;
  left: 890px;
  color: white;
  font-size: 21px;
}
